import React from "react"
import { graphql } from "gatsby"
import EmbedMap from "../../components/EmbedMap"

import "./style.scss"

const IndexPage = props => {
  let data = props.data.allKivo.edges

  return <EmbedMap data={data} currentPath={props.location.pathname} />
}

export default IndexPage

export const pageQuery = graphql`
  query EmbedQuery {
    allKivo(filter: { id: { ne: "dummy" } }) {
      edges {
        node {
          id
          spot_id
          address
          geometry {
            coordinates
          }
          name
          municipality
          postal_code
          operator
          opening_hours_en
          opening_hours_fi
          opening_hours_sv
          occupied
          materials {
            name
            code
          }
          description_en
          description_fi
          description_sv
        }
      }
    }
  }
`
