import React, { useEffect } from "react"
import { connect } from "react-redux"

import MapWrapper from "../mapWrapper"
import MapPopUp from "../mapPopUp"
import { setMapCenter } from "../../state/app"

import "./style.scss"

const EmbedMap = ({ data, popUpOpen, currentPath, setCenter }) => {
  return (
    <div>
      <div id="pageContent">
        {popUpOpen ? <MapPopUp /> : null}

        <MapWrapper data={data} embed={true} />
      </div>
    </div>
  )
}

export default connect(
  state => ({
    popUpOpen: state.app.popUpOpen,
  }),
  null
)(EmbedMap)
